/* =======================================================================
## ++ Menu systems.
========================================================================== */

#nav-main {
  background-color: $nav-bg-colour;
  text-align: center;
  position: relative;
}

#main_nav_ul {
  height: $nav_bar_height;
  list-style-type: none;
}

#nav-main > ul {
  width: $site-width;
  margin: 0 auto;
  height: $nav_bar_height;
  background-color: $nav-bg-colour;
}

#nav-main > ul > li {
  display: inline-block;
  height: 100%;
  padding: 16px 27px;
  vertical-align: middle;
  overflow: hidden;
  top: 0;
	&:hover {
	  background-color: $nav-bg-colour-hover;
	  border-radius: 10px;
		&a {
		  color: $midgreen;
		}
	}
}

.nav-drop-item:hover>a{
  color:$newyello;
}

.nav-main > ul > li > a {
  color: $nav-text-colour;
  font-size: $nav-font-size;
  letter-spacing: 1.2px;
  vertical-align: middle;
}

.nav-main > ul > li:hover > a {
  color: $midgreen;
  font-size: $nav-font-size;
  letter-spacing: 1.2px;
  vertical-align: middle;
}

.nav-main > ul > .current_page_parent > a {
  color: $midgreen;
}

//spacers
.nav_menu_spacers {
  width: 5px;
  display: inline;
  padding-top: calc(#{$nav_bar_height} / 2.6);
}

.nav_menu_spacers:after {
  color: $nav-bar-item-spacer-color;
  content: $nav-bar-item-spacer-char;
}

.nav_menu_spacers:last-child {
  display: none;
}

//dropdown stuff:
.dropdown-content {
	padding: 0px 15px;
  display: none;
  position: absolute;
  clear: both;
  background-color: #f9f9f9;
  min-width: 160px;
  width: $dropdown-width;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 22px 26px;
  z-index: 1;
}

#sub-menu{
	width:$dropdown-width;
	background-color: $darknavy;
  clear: both;
  transform: translateX(-27px);
  border-bottom-left-radius: 10px!important;
	border-bottom-right-radius: 10px!important;
	hr {
		opacity:0.1;
		&:last-child {
			display: none;
		}
	}
}

#main_nav_ul > .current-menu-item{
	background-color:$nav-bg-colour-hover;
	border-radius: 10px;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	&:hover{
		background-color:$nav-bg-colour-hover;
		border-radius: 10px;
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
}

.menu-item-has-children > #sub-menu{
	top:-90;
}

.menu-item-has-children:hover > #sub-menu{
	top:0;
}

.nav-drop-item{
	padding: 5px;
  padding-left: 0px;
  text-align: left;
}

.nav-drop-item a{
  color:$nav-text-colour;
  font-size: $sub-nav-font-size;
  letter-spacing: 1.4px;
}

.menu-item-has-children:hover .dropdown-content {
  display: table-column;
  margin-top: 50px;
}

.menu-item-has-children{
	display: inline-block;
	border-bottom-left-radius: 0px!important;
	border-bottom-right-radius: 0px!important;
}

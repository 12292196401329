/* =======================================================================
## ++ Alerts
========================================================================== */

.alert{
	width: 150px;
	height: 150px;
	-webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
	clip-path: polygon(0 0, 0 100%, 100% 100%);
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 9999999999;
	opacity: 0.8;
	-webkit-transition-delay: 2s; /* Safari */
  transition-delay: 2s;
	-webkit-transition: 2s; /* Safari */
  transition: 2s;
  @include iphone-p{
      width:60px!important;
			height:60px!important;
			margin-left: 0px!important;
			margin-top: 28px!important;
			font-size: 32px!important;
  }
}

.alert:hover{
	opacity: 1;
}

.icon_container{
	width: 100%;
	height: 100%;
}

.alert_icon{
	margin-left: 20px;
	margin-top: 65px;
	font-family: FontAwesome; ///@TODO:check, might need quotes.
	font-size: 60px;
	color: white;
	text-align: center;
	width: 65px;

}

#thisAlert{
	-webkit-transition: width 2s; /* Safari */
  transition: width 2s;
	overflow:hidden;
}

.alert_text{
	margin-top:4px;
	width: 110px;
	font-size: 14px;
	color: white;
	text-align: center;
}

.alert-scrollout{
	height: 150px;
	float: right;
	position: fixed;
	bottom: 0px;
	left: 0px;
	@include iphone-p{
			height:60px!important;
  }
}

.alert-scrollout>h2{
	font-size:30px;
	color: $white;
	margin-left:200px;
	margin-top:49px;
	@include iphone-p{
		margin-top:5px;
		font-size:15px;
		margin-left:20px;
  }
	@include tablets-p{
		font-size: 14px!important;
		margin-left: 132px!important;
		margin-top: 64px!important;
	}
}

/* =======================================================================
## ++ Newspage only stuff..
========================================================================== */

.latest_news_single {
  margin: 30px 0px;
  background-color: $lightblue;
  padding: 20px;
  border-radius: 20px;
	&:hover {
	  background-color: $lightgreen;
	}
}

.latest_news_single > a > h2 {
  font-size: 25px;
  color: $darknavy;
}

.news_date {
	font-size: 15px;
	margin: 10px 0px;
	color: $darknavy !important;
  @if $display-news-date == y {
    display: block;
  } @else if $display-news-date == n {
    display: none;
  }
}

/* =======================================================================
## ++ Site Variables!!
## ++ Theme Name: Holycross Primary School
## ++ Child of: _s
## ++ Theme URI: http://www.themustardagency.com
## ++ Description: Designed by <a href="http://www.themustardagency.com">TheMustardAgency</a>.
## ++ Author: Alex Woollam
## ++ Designer: Dani Wilkinson
## ++ Version: 0.8(Beta)
## ++ Tags: Sass, Gulp, Twig
========================================================================== */


/* ==== Colour settings ====*/

$lighterblue:#e8f6ff;
$lightblue:#dceffb;
$darkerblue:#043765;
$darkerdarkblue:#1574c9;
$lightnavy:#2475bc;
$darknavy:#16558d;
$lightgreen:#82d255;
$midgreen:#6cc03d;
$darkgreen:#4ca917;
$lightgrey:#90959f;
$grey:#8d919c;
$darkgrey:#565656;
$darkergrey:#393839;
$white:#fff;
$yellow:#e6fabf;
$newyello:#F2C944;

$h1:#16558d;
$h2:#2fb14b;
$h3:#e03337;
$h4:#f6c231;
$h5:#42bfeb;
$h6:#8d919c;


/* ============ General Settings ============ */

$main-background-color: $lighterblue;
$main-page-background-color: $lighterblue;

/* ------------ shoutouts to colours, because im lazy yo ----------- */

$red: #e51a29;

/* ------------ base widths ----------- */

$site-width: 1024px;

/* ------------ Site Header ------------ */

$header-bg-colour: white;
$logo_position:center;
$logo: 'images/Holy-Cross-Logo.png';


/* ------------ Nav Bar ------------ */

$nav_bar_height: 50px;

$nav-bg-colour: $lightnavy; /* can be adjusted for outside colours - check _header.scss*/
$nav-bg-colour-hover: $darknavy;
$nav-text-colour: $lighterblue;
$nav-font-size: 16px;
$sub-nav-font-size: 16px;
$nav-bar-item-spacer-char: '';
$nav-bar-item-spacer-color: #ebdf58;

/* dropdwons stuff */

$dropdown-width: 320px!important;


/* ------------ Sidebar ------------ */

/* set sidebar height HACKY HACKY HACKY! fix somepoint*/

$sidebar-height: 100px;
$sidebar_item_height: 50px;

/*
	Sidebar, left or right
							*/

$sidebar-side: left;
$sidebar-width: 25%;

/*
	Sidebar, menu header settings
							*/

$sidebar_has_title: n; //yes or no

$sidebar-title-size: 28px;
$sidebar-title-align: center;
$sidebar-title-color: $red;
$sidebar-title-bg: white;

/*
	Sidebar, menu item settings
							*/
$sidear-font-size: 16px;
$sidebar_bold_on_hover: y; //bold items text on hover? y or n
$sidebar-text-align: left;
$sidebar-bg: $lightblue;
$sidebar-bg-hover: $lightnavy;
$sidebar-text: $lightgrey;
$sidebar-text-hover: $lighterblue;

/* ------------



	General Page





	------------ */

/*
	page header image
							*/

$page-header-height: 226px;


/* ------------- quicklinks -----------------*/


 $quicklinks-height: 170px; /* 90 for button 190px for photo seems to work well?  */
 $quicklinks-button-height:90px;
 $quicklinks-bg-color: $darkgreen;
 $quicklinks-container-bg-color: $darkgreen;

 $quicklinks-inner-calc: calc(#{$quicklinks-height} - #{$quicklinks-button-height};); //(dont edit this)


/* ------------ Footer and Pre-footer ------------ */


/*
	pre footer
							*/


$pre-footer-height: 250px;
$pre-footer-bg-color: $white;

/*
	 footer
							*/

$footer-nav_width-adjust: 300px;
$footer-height: 40px;
$footer-bg-color: $lighterblue;

/* ------------




	Home Page Page





	------------ */

/*
	Header Slider?
							*/

$home_slider_height: 650px;;

/*
	News and events section
							*/

$news-and-events-height:  400px;

$news-and-events-bg-colour: $lightblue; /*main bg for both*/

$news-bg-colour: $lightblue;
$events-bg-colour: $lighterblue;

$which_side_for_events: right; //left or right.. duh.

$font-size-for-viewall: 18px;

$alternate-news-itmes-colours: y; //(y)es or (n)o
$latest-news-item-bg-colour: rgba(225,225,225,0.5);
$latest-news-item-bg-colour-alt: rgba(225,225,225,0.2);
$latest-news-item-bg-colour_hover: rgba(225,225,225,0.2);
$latest-news-item-bg-colour-alt_hover: rgba(225,225,225,0.5);

$home_news_date_colour: $white;
$home_news_date_align: center;
$home_news_date_font_size: 18px;

$home_news_title_colour: $darkgrey;
$home_news_title_align: center;
$home_news_title_font_size: 28px;

$home_news_content_colour: $red;
$home_news_content_align: center;
$home_news_content_font_size: 15px;

$home_news_read_colour: $red;
$home_news_read_align: right;
$home_news_read_font_size: 20px;



/* ------------




	Latest News Page





	------------ */

/*
	Display Date?
							*/

$display-news-date: y; //(y)es or (n)o



/* ------------




	Gallery Page






	------------ */



/*
	number in column?
							*/

$gallery-column-width: 3;

/* image height?

	3 colums best at 160px;
	2 columns best at 190px;
	will automate!!!

	 */

$gallery-column-height: 160px;

/* =======================================================================
## ++ Quicklinks for pre footer: two setups changed to school settings in ACF. ====
========================================================================== */

.quicklinks_container {
  height: $quicklinks-height;
  width: 100%;
  background-color: $quicklinks-bg-color;
  @include iphone-p{
      height:360px!important;
  }
  @include tablets-p{
      height:270px!important;
  }
}

/*-- image setup -- */

.quicklinks_inner {
  width: $site-width;
  margin: 0 auto;
  height: 100%;
  background-color: $quicklinks-container-bg-color;
	padding-top: calc(#{$quicklinks-inner-calc} / 2);
  @include tablets-p{
      width:100%!important;
  }
  @include iphone-p{
      width:100%!important;
  }
}

.quicklinks_inner .quicklink_item {
  height: 100%;
  float: left;
  background-size: cover;
  opacity: 1;
	&:hover {
	  opacity: 1;
	}
}


/*-- button setup --*/

.quicklinks_inner .quicklink_item-button {
  height: 100%;
  float: left;
  background-color: $midgreen;
  background-size: cover;
  height: $quicklinks-button-height;
  text-align: center;
  margin-top: 25px;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  border-color: $lightgreen;
  margin: 0 10px;
  @include tablets-p{
      width: calc(50% - 20px)!important;
      margin-bottom: 15px!important;
  }
  @include iphone-p{
      width: calc(100% - 20px)!important;
      margin-bottom: 5px;
  }
}

.quicklinks_inner > .quicklink_item-button > a {
  color: $lightgreen;
}

.quicklink_item-button_a:hover .quicklink_item-button {
  background-color: $midgreen;
  border-color: $darkgreen;
  opacity: 0.75;
  color: $yellow;
}

.quicklink_item-button_a:active .quicklink_item-button {
  opacity: 1;
  border-width: 4px;
}

.quicklink_item-button_a:hover .quicklink_item-button .quicklink_button_left {
  background-color: $lightgreen;
  border-color: $yellow;
}

.quicklink_item-button_a:hover .quicklink_item-button .fa {
  color: $yellow;
}

.quicklink_item-button_a:hover .quicklink_button_right p {
  color: $yellow;
}

.quicklink_button {
  text-align: left;
  position: relative;
}

.quicklink_button_left {
  width: 22%;
  float: left;
  display: inline;
  background-color: $darkgreen;
  border-radius: 100%;
  height: 80%;
  padding: 10px;
  margin: 3%;
  @include iphone-p{
      width: 24%;
  }
}

.quicklink_button_right {
  width: 70%;
  float: right;
  display: inline;
}

.quicklink_item-button_a {
  color: white;
}

.quicklink_button p {
  margin-top: 24px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 1.2px;
  color: white;
  text-transform: uppercase;
  line-height: 20px;
  @include tablets-p{
      font-size: 15px;
  }
  @include iphone-p{
      font-size: 18px;
  }
}

.quicklink_button .fa {
  color: white;
  font-size: 30px;
  width: 50px;
  text-align: center;
  margin-top: 10px;
}

/* =======================================================================
## ++ Globals
========================================================================== */

html{
    font-size: 100%;
}

body{
    -webkit-font-smoothing: antialiased;
    background-color:$main-background-color;
}

#content{
  height: auto;
}

.content-wrapper{
  height: auto;
  min-height: 50vh;
}

.main_page_wrap{
  overflow: hidden;
}

.article-content::after{
  content:"";
  height:90px;
}

article{
  background-color: $main-page-background-color;
  min-height: 50vh;
  height: auto;
}

*{
    box-sizing:border-box;
}

/* =======================================================================
## ++ Cleafix - no need to fiddle!!!
========================================================================== */

/* float clearing for IE6 */
* html .clearfix{
 height: 1%;
 overflow: visible;
}

/* float clearing for IE7 */
*+html .clearfix{
 min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after{
 clear: both;
 content: ".";
 display: block;
 height: 0;
 visibility: hidden;
 font-size: 0;
}

.clr{clear:both;}

///End clearfix.


.page_heading_container{
	position: relative;
	width:100%;
}

.page_heading_image{
  width:96%;
  margin: 30px;
  height:$page-header-height;
  object-fit: cover;
  border-radius: 20px;
  @include iphone-p{
      margin: 2%!important;
      width: 96%!important;
  }
  @include tablets-p{
      width: 88%!important;
  }
}

//content header images//

.page_heading_image{
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
}

.page_heading_image_over{
	background-image: url(./images/blue_bird.svg), url(./images/content_clouds.svg);
	background-repeat: no-repeat;
	background-position: 18% 10%, -18% -35%;
	background-size: 66px, 250px;
	height:100%;
	width:100%;
	top: 0;
  left: 0;
	position:absolute;
}

#content {
  width: $site-width;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  @include tablets-p{
      width:100%!important;
  }
  @include iphone-p{
      width:100%!important;
  }
}

.article-content{
  padding: 30px;
  padding-left: 50px;
  padding-right:0px;
  @include iphone-p{
    padding-left: 10px!important;
    padding-right:10px!important;
  }
  @include tablets-p{
      padding-left: 30px!important;
      padding-right: 30px!important;
  }

}

.post-wrapper{
  margin: 30px;
}

/*  Staff page  */

.teams:nth-child(odd){
  border-radius: 10px;
  background-color: $white;
}

.teams{
padding: 30px;
}

.team_members > h3 {
  color: $darknavy;
  padding-top:10px;
  padding-bottom: 10px;
}

.team_members > p {
///empty
}

/* curriculum page */

.curriculum_grid{
  padding: 30px;
}

.curriculum_grid > li > a{
  padding-bottom: 15px;
}

.curriculum_grid:nth-child(odd){
  border-radius: 10px;
  background-color: $white;
}


/*other accordions*/

.omsc-accordion{
  margin: 0em 0;
  background-color: #2ba659;
  text-align: center;
  font-weight: 700;
  padding: 0em;
  display: block;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.5s ease-in-out;
  border-bottom: 1px solid #30bb64;
}

.omsc-toggle>.omsc-toggle-title{
  padding:30px;
  font-family: 'Roboto', sans-serif;
}

.omsc-expanded>.omsc-toggle-title:after {
    content: '\f00d'!important;
    top: 30px!important;
    left: 30px!important;
}


.omsc-toggle-title:after {
  content: '\f067'!important;
  top: 30px!important;
  left: 30px!important;
}

.omsc-toggle-inner{
  background-color: #eee;
  font-size: 1em;
  line-height: 1.5em;
}

.omsc-toggle{
border: 1px solid #000;
}

.omsc-toggle-inner{
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: #565656;
  line-height: 1.4;
  font-size: 16px;
}

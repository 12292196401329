/* =======================================================================
## ++ Sidebar
========================================================================== */
@if $sidebar_has_title == y {
  .side-title {
    display: block;
  }
} @else
if $sidebar_has_title == n {
  .side-title {
    display: none;
  }
} @else {
  .side-title {
    display: none;
  }
}

.sidebar {
  background-color: $sidebar-bg;
  width: $sidebar-width;
  float: $sidebar-side;
  height: $sidebar-height;
  position: relative;
  padding-bottom: 900%;
  margin-bottom: -900%;
  @include iphone-p {
    display: none;
  }
}

.menu {
  background-color: $lightblue;
  padding-top: calc(#{$sidebar_item_height} / 2);
}

.menu > .menu-item {
  height: auto;
}

.menu > .menu-item > a {
  text-align: $sidebar-text-align;
  justify-content: $sidebar-text-align;
  font-size: $sidear-font-size;
  letter-spacing: 1.2px;
}

.menu .menu-item:hover {
  text-align: left;
  //  padding: 17px 20px;
  background-color: $sidebar-bg-hover;
  border-radius: 10px;
}

.menu > .current-menu-item:hover:after {
  content: "";
}

.menu > .current-menu-item {
  background-color: $sidebar-bg-hover !important;
  border-radius: 10px;
}

.sidebar .side-title {
  background-color: $sidebar-title-bg;
  height: 60px;
}

.menu li a {
  color: $sidebar-text;
}

.menu li:hover a {
  color: $sidebar-text-hover;
}

.menu > .current-menu-item a {
  color: $sidebar-text-hover;
  font-weight: 600;
}
@if $sidebar_bold_on_hover == y {
  .menu li:hover a {
    font-weight: 700;
  }
} @else {}

.sidebar h2 {
  font-size: $sidebar-title-size;
  text-align: $sidebar-title-align;
  color: $sidebar-title-color;
}

.menu .menu-item {
  text-align: $sidebar-text-align;
  padding: 17px 20px;
  background-color: $sidebar-bg;

  &hover {
    background-color: $sidebar-bg-hover;
    border-radius: 10px;
  }
}

.menu-sidebar-container > .menu > li {
  width: 100%;
}

.content-wrapper {
  @if $sidebar-side == right {
    margin-left: 0;
    margin-right: $sidebar-width;
  } @else {
    margin-left: $sidebar-width;
    margin-right: 0;
  }
}

.content-wrapper {
  width: calc(100% - #{$sidebar-width});
  @include iphone-p {
    margin-left: 0%!important;
    margin-right: 0%!important;
  }
}

.side-title h2 {
  padding-top: 15px;
}

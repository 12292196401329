


#popup{
	display: flex;
}

.popup{
	width: 100%;
	height: 100vh;
	background-color:black;
	opacity:0.8;
	z-index: 999999999999999;
	position: absolute;
    align-items: center;
	}

.popup_content{
	width: 600px;
	height: 400px;
	background-color: white;
	margin: auto;
	opacity: 1;
}

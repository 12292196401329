/* =======================================================================
## ++ Fonts!
========================================================================== */

///General Font Sheet

///Importing Roboto form google:
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

///Setting all fonts to Roboto:

font, p, a, h1, h2, h3, h4, h5, li{
	font-family: 'Roboto', sans-serif;
}

a, h1, h2, h3, h4, h5{
	text-transform: capitalize;
}

.article-body > ul {
	list-style: disc;
}

.article-body > ul > li {
	margin-bottom: 30px; color:$darkgrey;
}

.article-body > ol {
	list-style-type: decimal;
	list-style-position: outside;
	padding-left:10%;
}

.article-body > ol > li {
	margin-bottom: 30px;
	color:$darkgrey;
}

a{
	font-size: 18px;
	text-decoration: none;
	color:$darknavy;
	&:hover{
		color:$midgreen;
	}
}

h1, h2, h3, h4, h5, h6{
	letter-spacing: 1.2px;
}

h1{
	font-size:22px;
}

h2{
	font-size:20px;
}

h3{
	font-size:18px;
}

h4{
	font-size:16px;
}

h1{
	color: $h1;
}

h2{
	color: $h2;
}

h3{
	color: $h3;
}

h4{
	color: $h4;
}

h5{
	color: $h5;
}

h6{
	color: $h6;
}

strong{
	font-weight: bold;
	color: #565656;
	line-height: 1.4;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
}

em{
	font-style: italic;
}

blockquote{
	font-weight: light;
	font-style: italic;
}

.article-h1{
	margin-bottom: 32px;
	font-size: 32px;
	color:$darknavy;
}

p{
	margin-bottom: 32px;
	font-size: 18px;
	color:$darknavy;
	@include tablets-p{
			font-size: 14px!important;
	}

}

.article-body > .item > a >h4{
	color: $darkerblue;
}

.article-body > .item:hover > a >h4{
	color: $midgreen;
}

.article-body p {
	color: $darkgrey;
	line-height: 1.4;
	font-size: 16px;
}

.article-body > .item {
	margin: 11px 0px;
}

.news_items > .item > a >h4{
	color: $midgreen;
}

.news_items > .item:hover > a >h4{
	color: $lightnavy;
}

.right_header_bottom > p {
	color:$darknavy;
	font-size:16px;
	@include tablets-p{
			font-size:14px!important;
	}
	@include iphone-p{
			font-size:14px;
	}
}

.left_header_bottom > p {
	color:$darknavy;
	font-size:16px;
	@include tablets-p{
			font-size:14px!important;
	}
	@include iphone-p{
			font-size:14px;
	}
}

/* =======================================================================
## ++ Contact Page
========================================================================== */

///Generated contact page. Pos unused, check with Dani.

.news_items_intro{

}

.contact-row1{
  width: 100%;
  display: inline-block;
}

.contact_left{
  width: 50%;
  float: left;
}

.contact_right{
  width: 50%;
  float: left;
  text-align: right;
}

.contact-row2{
  width: 100%;
}

/* =======================================================================
## ++ Homepage only stuff..
========================================================================== */

.latest_news_events_row{
	width:$site-width;
	background-color:$news-and-events-bg-colour;
	height: $news-and-events-height;
	@include tablets-p{
			width:100%;
	}
	@include iphone-p{
			width:100%;
	}
}

.home_events h1, .news_events_home_block h1{
	text-align: center;
	font-size: 30px;
	margin-bottom: 20px;
	font-weight: 700;
	color: $grey;
	letter-spacing: 2px;
}

.news_events_home_block p{
	text-align: center;
	color: $darkergrey;
	margin-bottom: 17px;
	line-height: 1.3;
}

.news_events_home_block a {
	text-align: center;
	margin: 0 auto;
}

.view_all{
	width: 100%;
	text-align: center;
	&a{
		font-size:$font-size-for-viewall;
	}
}

.home_events{
	width: calc(#{$site-width}/2);
	float:$which_side_for_events;
	height:100%;
	background-color:$events-bg-colour;
	padding: 0px;
	@include tablets-p{
			width:50%;
	}
	@include iphone-p{
			width:100%!important;
	}
}

.home_news{
	width: calc(#{$site-width}/2);
	float:left;
	height:100%;
	background-color:$news-bg-colour;
	padding: 40px;
	padding-top:5px;
	@include tablets-p{
			width:50%;
	}
	@include iphone-p{
			width:100%!important;
			padding: 21px!important;
			height: 119%!important;
	}
}

.tp-revslider-mainul{
	@include iphone-p{
			width:100%!important;
	}
}

.home_news_single{
	padding: 5px;
	margin:10px;
	text-align: center;
}

.home_news_date{
	color: $home_news_date_colour;
	text-align: $home_news_date_align;
	font-size:$home_news_date_font_size;
	margin-top: 6px;
	margin-bottom:6px;
}

.home_news_single > h2,
.home_news_single > a > h2{
	margin-top: 20px;
	color: $home_news_title_colour;
	text-align: $home_news_title_align;
	font-size:$home_news_title_font_size;
	margin-bottom: 30px;
}

.event_date{
	text-align: center;
}

.welcome_button{
	margin: 0 auto;
	width: 148px;
}

.home_news_single > .event_image{
	font-family: FontAwesome; ///TODO: check if it wants quotes.
	color:$midgreen;
	font-size:70px;
	text-align:center;
	margin-top:20px;
	margin-bottom: 20px;
}

.home_news_single > a > p{
	color: $home_news_content_colour;
	text-align: $home_news_content_align;
	font-size:$home_news_content_font_size;
}

.home_news_single > .read_more{
	text-align: $home_news_read_align;
	&a{ ///TODO: check
		color: $home_news_read_colour;
		font-size:$home_news_read_font_size;
	}
}

.homenewsimg{
	margin: 0 auto;
	width: 277px;
	height: 174px;
	border-radius: 20px;
	background-size: cover;
	position: relative;
}

.homenewsimg_blank{
	margin: 0 auto;
	width: 277px;
	height: 174px;
	border-radius: 20px;
	background-image: url($logo);
	background-color:$lightnavy;
	background-size: 150px;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
}

.news_date_container{
	background-color:$darkgreen;
	width: 60%;
	padding:5px;
	opacity:0.9;
	bottom:0;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 10px;
	position: absolute;
}

@if $alternate-news-itmes-colours == "y"{
.home_news > .home_news_single:nth-child(even){
	background-color:$latest-news-item-bg-colour;
	}
.home_news > .home_news_single:nth-child(odd){
	background-color:$latest-news-item-bg-colour-alt;
	}
.home_news > .home_news_single:hover:nth-child(even){
	background-color:$latest-news-item-bg-colour_hover;
	}
.home_news > .home_news_single:hover:nth-child(odd){
	background-color:$latest-news-item-bg-colour-alt_hover;
	}

} @else {

.home_news > .home_news_single:nth-child(even){
	background-color:$latest-news-item-bg-colour;
	}
.home_news > .home_news_single:nth-child(odd){
	background-color:$latest-news-item-bg-colour;
	}
.home_news > .home_news_single:hover:nth-child(even){
	background-color:$latest-news-item-bg-colour_hover;
	}
.home_news > .home_news_single:hover:nth-child(odd){
	background-color:$latest-news-item-bg-colour_hover;
	}
}

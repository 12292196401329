/* =======================================================================
## ++ Home Page Slider (When slider rev isnt being used...)
========================================================================== */


.mySlides img{
  height: $home_slider_height;
  object-fit: cover;
  width: $site-width;
  @include tablets-p{
      width:100%;
  }
  @include iphone-p{
      width:100%;
  }
}

//generated:

/* Slideshow container */
.slideshow-container {
 // max-width: 1000px;
  width: $site-width;
  position: relative;
  margin: 0;
  height: $home_slider_height;
  @include tablets-p{
      width:100%;
  }
  @include iphone-p{
      width:100%;
  }
}

.mySlides {
    display: none; transition: 0.2s ease; position:relative;
}

.slider_main_text{
	position:absolute;
	margin-top:200px;
	margin-left: 30px;
	width: 50%;
	top:0;
}

.slider_sub_text{
	position:absolute;
	margin-top:350px;
	margin-left: 30px;
	width: 50%;
	top:0;
}

.slider_buttons{
  display:inline-block;
  margin-right: 20px;
  text-align: center;
}

.slider_main_text>h1{
	font-size:44px;
	color: $yellow;
	font-weight: 800;
	line-height: 1.0;
}

.slider_sub_text>p{
	font-size:34px;
	color: $lighterblue;
	line-height: 1.4;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 85%;
  width: 50px;
  height: 50px;
  margin-top: -22px;
  padding: 10px;
  padding-left: 19px;
  color: white;
  transition: 0.6s ease;
  border-radius: 50px;
  background-color: rgba(164, 164, 164, 0.6);
  font-family: FontAwesome;
  font-size:28px;
}

.prev{
	  right: 12%;
}

/* Position the "next button" to the right */
.next {
  right: 5%;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(164, 164, 164, 0.8);
}

/* Caption text */
.text {
  color: $red;
  font-size: 35px;
 // padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  //padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor:pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

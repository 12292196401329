/* =======================================================================
## ++ Header
========================================================================== */



#headerwrapper{
	position: relative;
}

.left_header{
	width:calc( #{$site-width} / 2 - #{$sidebar-width} );
	float:left;
	margin-top: 35px;
	@include iphone-p{
			display: none;
	}
	@include tablets-p{
      width:33.3%!important;
  }
}

.left_header_top{

}

.left_header_bottom, .right_header_bottom{

	& p {
		line-height: 1.5;
		margin-bottom: 0px;
		margin-top:20px;
	}

}

.logo{
	float: left;
	margin: 0 auto;
	background-image: url('#{$logo}');
	background-size: 52%;
	background-position:center;
	height:100%;
	background-repeat: no-repeat;
	width: calc( #{$sidebar-width}*2);
	padding:20px;
	@include iphone-p{
			width: 100%!important;
	}
	@include tablets-p{
      width:33.3%!important;
			background-size:89%;
  }
}

.right_header{
	width:calc( #{$site-width} / 2 - #{$sidebar-width} );
	float:right;
	text-align: right;
	margin-top: 35px;
	@include iphone-p{
			display: none;
	}
	@include tablets-p{
      width:33.3%!important;
  }
}

.right_header_top{
	@include tablets-p{
			display:none!important;
	}
}

.right_header_bottom{

}

.header{
	height:flex;
}

.header_wrapper{
	height:214px;
	background-color: $header-bg-colour;
	position:relative;
}

.header_inner{
	height: 100%;
	width:$site-width;
	margin: 0 auto;
	@include tablets-p{
			background-color: $white;
			width:90%!important;
	}
	@include iphone-p{
			background-color: $white;
			width:100%!important;
	}
}

//header images//

.header_wrapper{
	background-repeat: no-repeat;
	background-position: 65% 50%, 70% 30%, 30% 30%;
	background-size: 50px, 180px, 130px;
}


/* -=========================================  Pre Header Quicklinks   */

.header_quicklinks_wrap{
	height: 90px;
	width: 100%;
	background-color: $midgreen;
	position:absolute;
	z-index:1;
	transition: all 0.5s ease 0.5s;
}

.close_quicklinks{
	display: inline;
	min-width:0px;
}

.shadow{
	-moz-box-shadow:    inset 0 0 10px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
  box-shadow:         inset 0 0 10px rgba(0,0,0,0.2);
}

.header_quicklinks_contain{
	width: $site-width;
	margin: 0 auto;
}

.quicklinks-ul{
	list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 0px;
	padding-bottom: 15px;
	padding-left: 15px;
  display: inline-block;
}

.quicklinks-li{
	float: left;
	margin-right:52px;
	&:hover > a {
		color: white;
	}
}

#quicklinksDropdownBtn{
	background-color:$darknavy;
	height: 55px;
	width: flex;
	color:$lightblue;
	border-radius: 8px;
	font-size: 18px;
	text-transform: capitalize;
	font-family: 'Roboto', sans-serif;
	border-width: 0px;
	margin:10px 0px;
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
	padding-top:17px;
	font-weight:500;
	outline:0;
	max-width: 230px;
	min-width: 230px;
	letter-spacing: 1.5px;
	@include tablets-p{
			display:none;
	}
	@include iphone-p{
			display:none;
	}
	& .icon{
		float:right;
	}
	&:hover{
		background-color:$darkerblue;
		opacity:1;
	}

	&:active{
		background-color:$darkerdarkblue;
		border-width:0px;
		border-color:$lightnavy;
	}
}

#quicklinksDropdownBtn:hover > #quicklinksDropdown{
	opacity:1;
	transform: translate(-15px, 0px);
}

#quicklinksDropdownBtn > #quicklinksDropdown{
	margin-top: 10px;
	z-index: 9999999999999999;
	position: absolute;
	background-color: $darkerblue;
	color: white;
  border-radius: 0px;
	width: 230px;
  padding: 5px;
	padding-left: 16px;
	padding-right: 10px;
	padding-bottom: 23px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
  text-align: left;
  transform: translate(-15px, -300px);
	opacity:0;
	transition: all 1.25s ease-in-out;
	-moz-transition: all 1.25s ease-in-out;
	-webkit-transition: all 1.25s;
}


#quicklinksDropdown>ul>li>a{
	color:$white;
	font-size: 16px;
	line-height: 1.5;
	z-index:999999;
	display: block;
	position: relative;
}

#quicklinksDropdown>ul>li:hover>a{
	color:$newyello;
}

.quicklinks-selection{

}

/* =======================================================================
## ++ Media Queries
========================================================================== */

/*
    Used for media queries.
    Add these mixins in your normal scss flow.

    Eg.
    .container{
        width:1024px;

        @include tablets{
            width:90%;
        }
    }
*/

@mixin lowresmonitors{
        @media screen and (min-width: 1024px) and (max-width: 1350px){ @content;}
}

@mixin tablets-l{
        @media screen and (min-width: 768px) and (max-width: 1024px){ @content; }
}

@mixin tablets-p{
        @media screen and (min-width: 568px) and (max-width: 768px){ @content; }
}

@mixin iphone-l{
        @media screen and (min-width: 320px) and (max-width: 568px){ @content; }
}

@mixin iphone-p{
        @media screen and (max-width: 375px){ @content; }
}




/* =======================================================================
## ++ Unit transform
========================================================================== */

/*
    Used for making containers have width in percentages.
    Usage: define elemnt width in px and the width of parent elemnt in px.
    eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}
*/

@function cp($target, $container) {
    @return ($target / $container) * 100%;
}

/*
    Used for making px values convert to rem values
    Usage: define font-size in px and it will convert to rems
    eg. font-size: rem(14px);
*/

@function rem($target, $context: $base-font-size) {
   @if $target == 0 { @return 0 }
   @return $target / $context + 0rem;
}
$base-font-size:16px;

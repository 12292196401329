/* =======================================================================
## ++ The Footer
========================================================================== */

#footer {
  background-color: $pre-footer-bg-color;
  width: 100%;
  float: left;
  height: calc(#{$pre-footer-height} + #{$footer-height});
  position: absolute;
  @include iphone-p{
      height:457px!important;
  }
}

.pre-footer {
  width: 100%;
  background-color: $pre-footer-bg-color;
  height: $pre-footer-height;
  position: relative;
  margin: 0 auto;
}

.pre-footer-contain {
  width: $site-width;
  margin: 0 auto;
  height: 100%;
  position: relative;
  padding-top: 30px;
  @include tablets-p{
      width:90% !important;
  }
  @include iphone-p{
      width:100%!important;
  }
}

.pre-footer-left {
	float: left;
	width: calc(#{$site-width} - 65%);
	height: 100%;
	text-align: left;
	padding-top: 20px;
  font-size: 18px;
  color: $darkgrey;
	& p {
	  font-size: 18px;
    line-height: 1.5;
	  color: $darknavy;
    @include tablets-p{
        font-size: 16px;
    }
	}
	& a {
	  font-size: 18px;
	  color: $darknavy;
    text-transform: lowercase;
    @include tablets-p{
        font-size: 16px;
    }
	}
  @include iphone-p{
      width:100%!important;
      text-align: center!important;
  }
  @include tablets-p{
      width:40%!important;
  }
}

.pre-footer-left > h2,
.pre-footer-right > .backtotop > a > h2 {
  font-size: 25px;
  color: $lightnavy;
  margin-bottom: 30px;
  margin-right: 15px;
  display: inline;
  font-weight:700;
}

.pre-footer-right > ul > li {
  display: inline-block;
  float: right;
  padding-left: 15px;
  & a{
    color: $darkgrey;
  }
}

.pre-footer-right > .backtotop:hover > a > h2,
.backtotop:hover > a > .fa {
  color: $lightgreen;
}

.pre-footer-right > p {
  font-size: 18px;
  @include tablets-p{
      font-size: 16px;
  }
}

.backtotop > a > .fa {
  float: right;
  display: inline;
  color: $white !important;
  background-color: $grey;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
}

.backtotop > a > i {
  font-size: 20px;
  color: $white;
  padding-top: 4px;
}

.pre-footer-right {
  float: right;
  width: calc(#{$site-width} - 35%);
  height: 100%;
  text-align: right;
  padding-top: 20px;
  @include tablets-p{
      width:40%!important;
      font-size: 16px;
  }
  @include iphone-p{
      width:100%!important;
      text-align: center!important;
  }
}

.pre-footer-right > .backtotop {
  @include iphone-p{
      display:none!important;
  }
}

.madeby > p {
  color: $darknavy;
}

.pre-footer-contain {
  height: 100%;
  margin: 0 auto;
  //background-image:url(./images/orange_bird.svg), url(./images/footer_clouds.svg);
  background-repeat: no-repeat;
  background-position: center center, 50% 555%;
  background-size: 85px, 283px;
}

.footer-contain {
  width: $site-width;
  margin: 0 auto;
}

.footer_phone {
  color: $darkgrey;
  margin-bottom: 5px;
}

.footer_email {
  font-size: 18px;
  color: $darkgrey;
}

.footer_phone > b {
  font-weight: 700;
}

.footer {
  background-color: $footer-bg-color;
  height: $footer-height;
	display: none;
}

.footer_copywright {
  float: left;
  display: block;
  width: flex;
  padding-top: 10px;
	&p {
	  font-size: 15px;
	}
}

.copyright {
  margin-top: 30px;
  color: $darknavy;
  margin-bottom: 5px;
}

.pre-footer-right>ul{
  margin-top: 81px;

  @include iphone-p{
      display:none!important;
  }
}


.nav-footer {
  width: $footer-nav_width-adjust;
  display: block;
  float: left;
  padding-top: 10px;
	& ul {
  	width: $site-width;
  	margin: 0 auto;
		& li {
		  display: inline;
		  margin: 10px;
			& a { ///TODO: DEF CHECK SYNTAX HERE, looks dodgy af.
			  color: #fff;
			  font-size: 15px;
			}
		}
	}
}

/* =======================================================================
## ++ Buttons
========================================================================== */

// homepage slider buttons (.prev, .next ) are in the home_page_slider scss

//general buttons

button{
	background-color:$darknavy;
	height: 55px;
	width: flex;
	color:$lightblue;
	border-radius: 8px;
	font-size: 18px;
	text-transform: capitalize;
	border-width: 0px;
	margin:10px 0px;
	min-width: 150px;
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
	font-weight:500;
	outline:0;

	&:hover{
		background-color:$darkerblue;
		opacity:1;
	}

	&:active{
		background-color:$darkerdarkblue;
		border-width:0px;
		border-color:$lightnavy;
	}

}

button .icon { ///TODO: check syntax here.
	text-align: right;
	float:right;
	width:10px;
	padding-right: 20px;
}

button[type="green"]{
	background-color: $midgreen;
	text-align:center;
	color:$white;
}

button[type="green"]:hover{
	background-color: $darkerblue;
}


//quicklink popup controls.

button[lable="close_quicklinks"]{
	min-width: 52px;
}

button[lable="close_quicklinks"]>.icon{
	padding-right:25px;
}

//button for quicklinks - could be unused in this version.

button[lable="quicklinks"]{
	min-width: 230px;
	letter-spacing: 1.5px;
	@include tablets-p{
			display:none;
	}
	@include iphone-p{
			display:none;
	}
}

//button for slider

button[lable="slider"]{
	text-align: center;
}

//back buttons for single pages:

button[lable="back_btn"]{
	text-align: center;
	min-width:100px;
	width:200px;
}

input {
	background-color:$darknavy;
	height: 55px;
	width: flex;
	color:$lightblue;
	border-radius: 8px;
	font-size: 18px;
	text-transform: capitalize;
	border-width: 0px;
	margin:10px 0px;
	min-width: 150px;
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
	font-weight:500;
	outline:0;
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
  &::placeholder {
    color: $white;
  }
}



input[lable="search"]{
	height: 55px;
	width: 55px;
	//margin:10px 0px;
	padding-left: 15px;
	float:left;
	padding-right: 15px;
	text-align: left;
	background-color: $darknavy;
	border-width: 0px;
	color:$white;
	font-size: 18px;
	font-weight: 400;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	letter-spacing: 1.5px;
	text-transform: capitalize;
	@include tablets-p{
			display:none;
	}
	@include iphone-p{
			display:none;
	}
}

input[lable="search"]::placeholder{
	color:$white;
	letter-spacing: 1.5px;
	font-size: 18px;
	font-weight: 400;
	text-align: left;
	text-transform: capitalize;
}

button[lable="search"]{
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: right;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	min-width:20px;
	margin-right:6px;
	padding-left: 1px;
	@include tablets-p{
			display:none;
	}
	@include iphone-p{
			display:none;
	}
}

button[lable="search"]:hover{
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: right;
	min-width:20px;
	color:$newyello;
	background-color: $darknavy;
	padding-left: 1px;
}

button[lable="search"]:focus{
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: right;
	min-width:20px;
	margin-right:6px;
	padding-left: 1px;
}

button[lable="search"]:active{
	background-color: $darknavy;
  border-width: 0px;
  border-color: transparent;
	color: $white;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: right;
	min-width:20px;
	margin-right:6px;
	padding-left: 1px;
}
